import {
  Grid,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { youtubeLatest } from '../../api/services';
import uploadDefault from "../../assets/images/homeHero.jpg";
import planvisit from '../../assets/images/planvisit.jpeg';
import { LoadingDisplay } from '../../reusables/LoadingDisplay.js';
import { useLoadLatestEventData } from '../Hooks/useLoadData';
import { theme } from './Home.theme';
import { HomeHero } from './HomeHero';

//Import components
import LinksSection from './LinksSection.js';
import PastorSection from './PastorSection.js';
const AnnouncementSection = lazy(()=> import('./AnnouncementSection.js'));
//Lazy loading sections that are more loading intense for performance
const EventSection = lazy(()=> import ('./EventSection.js'));
const SermonSection = lazy(()=> import ('./SermonSection.js'));

const Home = () =>{
  const authenticated = JSON.parse(sessionStorage.getItem('ready'));
  const matches = useMediaQuery(theme.breakpoints.down('lg')); //For responsive design
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();
  const [youtubeId,setyoutubeId] = useState('7PZYdg1oe3s?si=4LzPlPKAhG-DL8vF');

  let filename = 'homeHero.jpeg';
  let ui = 'web';
  let key = 'home';
  let path = "home/image/";

  // const [status,error,hero] = useLoadFile({ui: ui,path:path,filename:filename,key:key});
  // const heroImage = useMemo(()=>{return (hero == "")?uploadDefault:hero},[status,hero]);
  const heroImage = uploadDefault;
  const [eventStatus,eventError,event] = useLoadLatestEventData({collection:'event'});
  const [announcementStatus,announcementError,announcement] = useLoadLatestEventData({collection:'announcement'});

  useEffect(()=>{
      if(authenticated){
        youtubeLatest().then(data => {
          setyoutubeId(data);   
          setisLoading(false);
        });
      }
      else{
        setTimeout(()=>{
            if(!isLoading){
              setisLoading(false);
            }
          },"20000");
      }
  },[authenticated]);
  
  return (
    <ThemeProvider theme={theme}>
      <Grid>
         <HomeHero   
          matches={matches}
          heroImage={heroImage}
          status={"success"}
          />
        <Grid variant='main'>
        {(eventStatus == 'success' && event?.image)&&
          <Suspense fallback= {<LoadingDisplay />} >
            <EventSection matches={matches} event={event} navigate={navigate}/>
          </Suspense>
         }
          <LinksSection planvisit={planvisit} matches={matches}/>
          <PastorSection matches={matches} />
          <Suspense fallback= {<LoadingDisplay />} >
            <SermonSection matches={matches} youtubeId={youtubeId} />
          </Suspense>
          {(announcementStatus == 'success' && announcement?.image)&&
          <Suspense fallback= {<LoadingDisplay />} >
            <AnnouncementSection matches={matches} section={announcement} sectionName={'announcement'} navigate={navigate}/>
          </Suspense>
          }
        </Grid>
      </Grid>
    </ThemeProvider>
  )   
}

export default Home;
