
import { KeyboardArrowDown, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Link as MuiLink,
  Popper,
  useMediaQuery,
  useScrollTrigger
} from '@mui/material';
import { red } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import woglogo from '../../assets/woglogo.png';
import { theme as hometheme } from '../Home/Home.theme';
import { iconStyle, linkStyle, linkStyleSelected, linkStyleWhite, theme } from './Header.theme';

export function Header() {
  const adminWidth = 250;
  const matches = useMediaQuery(hometheme.breakpoints.down('lg'));
  const trigger = useScrollTrigger({
    threshold:'500',
  })
  //Determine the current route
  const location = useLocation();
  const { hash, pathname, search } = location;
  const navigate = useNavigate();
  const [navBackground , setNavBackground]= useState('transparent');
  const [navBorder, setNavBorder] = useState();
  const [headerFade,setHeaderFade] = useState(false);
  const [loaded,setLoaded] = useState(false);
 
  const controlHeader = () =>{
    if(!pathname.includes('/login')){
      if(window.scrollY > trigger){
        setHeaderFade (true);
        setNavBackground("rgb(0, 0, 0,0.8)");
        setNavBorder('1px solid rgba(0, 0, 0,0.8)');
      
      }else{
        setNavBackground('transparent');
        setNavBorder('0px');
        setHeaderFade (false);
      }
    }
  }

  useEffect(()=>{
    if(pathname.includes('/admin/login')){
      setNavBackground('black');
    }
    setLoaded(true);
    window.addEventListener('scroll', controlHeader)
    return() => {
        window.removeEventListener('scroll', controlHeader)
      }
  },[pathname]);

  //Dropdown Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open,setOpen] = React.useState(false);
  const [openConn,setOpenConn] = React.useState(false);
  const [inMenu, setInMenu] = React.useState(false);
  
  const handleLogoClick = (event) => {
    navigate('/');
  }
  const handleClose = (event) => {
    setAnchorEl(null);
    if(open){
      setOpen(false);
    }else{
      setOpenConn(false);

    }
    setInMenu(false);
  };
   
  const handleHover = (event) =>{
    setAnchorEl(event.currentTarget);
    if(event.currentTarget.id == 'about-button'){
      setOpen(true);
    
    }else{
      setOpenConn(true);

    }
    setInMenu(true);
  }

  const id = open ? 'simple-popper' : undefined;
  const idConn = openConn ? 'connect-popper' : undefined;
  //handle menu item click
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Menu 
        anchorReference='anchorPosition'
        anchorPosition={{top: 100, left: 400}}
        anchorEl={anchorElMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem 
          onClick={handleCloseMenu}
        >
          <Link style={linkStyle} to={'/about'}>
          About
          </Link>
          </MenuItem>
      
        <MenuItem  
          onClick={handleCloseMenu}
          sx={{pl: 4}}
        >
          <Link 
            style={linkStyle}
          to={'/about'}>
          What We Believe
          </Link>
        </MenuItem>
        <MenuItem  
          onClick={handleCloseMenu}
          sx={{pl: 4}}
          >
          <Link 
            style={linkStyle}
          to={'/about/who'}>
          Who We Are
          </Link>
        </MenuItem>
        <MenuItem 
          onClick={handleCloseMenu}
          sx={{pl: 4}}
          >
          <Link 
            style={linkStyle}
          to={'/about/where'}>
          Where We've Been
          </Link>
        </MenuItem>
        <MenuItem 
          onClick={handleCloseMenu}
        >
          <Link 
            style={linkStyle}
          to={'/ministry'}>
          Ministries
          </Link>
        </MenuItem>
        <MenuItem
          onClick={handleCloseMenu}
        >
            <Link 
              style={linkStyle}
            to={'/event'}>
            Events
            </Link>
        </MenuItem>
        <MenuItem
          onClick={handleCloseMenu}
        >
            <Link 
              style={linkStyle}
            to={'/announcement'}>
            Announcements
            </Link>
        </MenuItem>
        <MenuItem 
          onClick={handleCloseMenu}
        >
            <Link 
              style={linkStyle}
            to={'/connect'}>
            Connect
            </Link>
        </MenuItem>
        {/* <MenuItem 
          onClick={handleCloseMenu}
          sx={{pl: 4}}
          >
          <Link 
            style={linkStyle}
          to={'/connect/visit'}>
            Plan A Visit
            </Link>
        </MenuItem> */}
        {/* <MenuItem 
          onClick={handleCloseMenu}
          sx={{pl: 4}}
        >
          <Link 
            style={linkStyle}
          to={'/connect/join'}>
            Join The Church
            </Link>
        </MenuItem> */}
        <MenuItem 
          onClick={handleCloseMenu}
          sx={{pl: 4}}
        >
          <Link 
            style={linkStyle}
          to={'/connect/prayer'}>
            Request Prayer
            </Link>
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <MuiLink style={linkStyle}
            target="_blank" href="https://giv.li/fw42tz"
          >
            Give
          </MuiLink>
        </MenuItem>
      </Menu>

      <AppBar 
        id='app-header'
        variant='header'
        position="fixed" color="inherit" 
        style={
          {
          width: pathname.includes('/admin')? `calc(100% - 325px)`:"",
          marginRight: pathname.includes('/admin')? `275px`:"",
          marginTop: pathname.includes('/admin')? `${60}px`:0,
          backgroundColor:`${navBackground}`,
          borderBottom:`${navBorder}`,
          transitionProperty:'background-color, border-bottom',
          transitionTimingFunction:'ease-in-out',
          transitionDuration:'0.4s',
          alignItems:'center'
        }}
        >
        <Grid variant={matches?'headerSmall':'header'}>
          <Grid variant={matches?'logoSmall':"headerlogo"}>
            {(woglogo != "") ?  
              <img style={{width:'18rem', objectFit:'contain'}}src={woglogo} onClick={handleLogoClick} />
            :
              <div style ={{height:"10vh"}}></div>}
          </Grid>
          
          <Grid variant="headermenucontainer">
          {matches ? 
            <MenuIcon style={{color:'white'}}
              onClick={handleMenu}
            ></MenuIcon>
            :
            <Grid variant="headermenu">
              <div style={{minHeight:"fit-content", minWidth:"fit-content"}} onMouseLeave={handleClose}>
                <Link 
                  style={pathname.includes('/about')? linkStyleSelected :linkStyleWhite} to={'/about'}
                  id={'about-button'}
                  aria-describedby={'about-button'}
                  onMouseEnter={e => handleHover(e)}
                >
                  About
                  <KeyboardArrowDown style={iconStyle} />
                </Link>
                
                <Popper
                  id={id}
                  sx={{ zIndex: 1200 }}
                  open={open}
                  anchorEl={anchorEl}
                  placement={'bottom'}
                  // transition
                >
                  <List
                    variant={'dropdownBackground'}
                  >
                    <ListItem disablePadding  onClick={handleClose}>
                      <ListItemButton
                      > 
                            <Link style={linkStyle} to={'/about'}>
                          <ListItemText primaryTypographyProps={{fontFamily:'League Spartan'}} primary="What We Believe" />
                          </Link>
                      </ListItemButton>
                    
                    </ListItem>
                    <ListItem disablePadding onClick={handleClose}>
                      <ListItemButton
                        > 
                          <Link style={linkStyle} to={'/about/who'}>
                          <ListItemText primary="Who We Are" />
                          </Link>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={handleClose}>
                      <ListItemButton> 
                      <Link style={linkStyle} to={'/about/where'}>
                        <ListItemText primary="Where We've Been" />
                        </Link>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popper>
              </div>
              <Link style={pathname.includes('/ministry')? linkStyleSelected :linkStyleWhite}  to={'/ministry'}>
                Ministries
              </Link>
              <Link
                  style={pathname.includes('/event')?  linkStyleSelected :linkStyleWhite} to={'/event'}>
                  Events
              </Link>
              <Link
                  style={pathname.includes('/announcement')?  linkStyleSelected :linkStyleWhite} to={'/announcement'}>
                  Announcements
              </Link>
              <div style={{minHeight:"fit-content", minWidth:"fit-content",backgroundColor: red}} onMouseLeave={handleClose}>
                <Link               
                  style={
                    pathname.includes('/connect')? linkStyleSelected :linkStyleWhite 
                  } 
                  to={'/connect'}
                  id={idConn}
                  aria-describedby={idConn}
                  onMouseEnter={e => handleHover(e)}
                >
                  Connect
                  <KeyboardArrowDown style={iconStyle} />
                </Link>
                <Popper
                  id={idConn}
                  sx={{ zIndex: 1200 }}
                  open={openConn}
                  anchorEl={anchorEl}
                  placement='bottom'
                >
                  <List
                    variant={'dropdownBackground'}
                  >
                    {/* <ListItem disablePadding onClick={handleClose}>
                      <ListItemButton
                      > 
                      <Link style={linkStyle} to={'/connect/visit'}>
                        <ListItemText primary="Plan A Visit" />
                        </Link>
                      </ListItemButton>
                    </ListItem> */}
                    {/* <ListItem disablePadding
                    onClick={handleClose}
                      >
                      <ListItemButton  
                      > 
                      <Link style={linkStyle} to={'/connect/join'}>
                        <ListItemText primary="Join The Church" />
                        </Link>
                      </ListItemButton>
                    </ListItem> */}
                    <ListItem disablePadding 
                    onClick={handleClose}
                      >
                      <ListItemButton> 
                      <Link style={linkStyle} to={'/connect/prayer'}>
                        <ListItemText primary="Request Prayer" />
                        </Link>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popper>
                </div> 
                <MuiLink style={pathname.includes('/give')? linkStyleSelected: linkStyleWhite} target="_blank" href="https://giv.li/fw42tz" >Give</MuiLink> 
            
            </Grid>
          }
          </Grid> 
        </Grid>
      </AppBar>
    </ThemeProvider>
  )
}