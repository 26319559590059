import { blue, red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { theme as hometheme } from '../Home/Home.theme';

let theme = createTheme({
    components: {
        MuiGrid:{
            variants: [
                {
                    props: {variant:'main'},
                    style:{
                        // backgroundImage:`url(${design})`,
                    
                        // borderTop:`5px solid ${hometheme.palette.primary}`, 
                        // height:"50vh",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        paddingBlock:"0vh"
                        
                    }
                },
                {
                    props: {variant:'heroGrid'},
                    style: {
                        // border: `4px dashed ${blue[500]}`,
                        display:"flex", 
                        flex:"1",
                        justifyContent:"center", 
                        backgroundColor:'black'
                    }
                },
                {
                    props: { variant: 'sectionTitle'},
                    style: {
                        // width:"80vw",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        // marginTop:"15vh",
                        minHeight:"15vh",
                        // border: `4px dashed ${orange[500]}`,
                    }
                },
                {
                    props:{variant: 'sectionContent'},
                    style:{
                        // border: `4px dashed ${orange[500]}`,
                        // width:"80vw",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        marginTop:"5vh",
                        minHeight:"20vh",
                        width:'90%'

                    }
                },
                {
                    props: { variant: 'ministryContactGrid'},
                    style:{
                      // border: `4px dashed purple`,
                      display:'flex',
                      // flex:1,
                      marginTop:'2vh',
                      flexDirection:'row',
                      // alignItems: 'center',
                      alignSelf: 'center',
                      width:'fit-content',
                      [hometheme.breakpoints.down('lg')]:{
                        flexDirection:'column',
                        alignItems:'center',
                       
                      }
                     
                    
                      
                    }
                  },
                  {
                    props: { variant: 'ministrySectionContent'},
                    style:{
                      // border: `4px dashed red`,
                      display:'flex',
                      // flex:2,
                      flexDirection:'column',
                      // alignItems: 'center',
                      alignSelf: 'center',
                      width:'fit-content',
                      
                      
                    }
                  },
              
            ]
        },
        
        MuiTypography:{
            styleOverrides :{
                root:({ownerState}) => ({
                    fontFamily: 'League Spartan',
                    fontWeight: 'normal',
                })},
            variants:[
                {
                    props: {variant: 'sectiontitle'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '2.2rem'
                        
                    }
                },
                {
                    props: {variant: 'eventtitle'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '1.9rem',
                        color:hometheme.palette.primary
                        
                    }
                },
                {
                    props: {variant: 'title'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '4.4rem'
                        
                    
                    }
                },
                {
                    props: {variant: 'sectionsub'},
                    style:{
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        
                    }
                },
                {
                    props: {variant: 'sectionbody'},
                    style:{
                        fontWeight: 'normal',
                        fontSize: '1rem'
                    
                    }
                },
                {
                    props: {variant: 'thin'},
                    style:{
                        fontWeight: 'light'
                    }
                },
                {
                    props: {variant: 'normal'},
                    style:{
                        fontWeight: 'normal',
                        marginBottom:"2%"
                    }
                },
                {
                    props: {variant: 'bold'},
                    style:{
                        fontWeight: 'bold'
                    }
                },
                {
                    props: {variant: 'bolder'},
                    style:{
                        fontWeight: 'bolder',
                  
                    }
                },
                {
                    props: {color:'text'},
                    style:{
                        color: hometheme.palette.secondary
                    }
                },
                {
                    props: {color:'title'},
                    style:{
                        color: hometheme.palette.secondary
                    }
                },
                {
                    props: {color:'main'},
                    style:{
                        color:hometheme.palette.primary
                    }
                },
                {
                    props:{size:'small'},
                    style:{
                        fontSize: '.8rem'
                    },
                  
                },
                {
                    props:{size:'medium'},
                    style:{
                        fontSize: '1rem'
                    },
                  
                },
                {
                    props:{size:'large'},
                    style:{
                        fontSize: '1.2rem'
                    },
                  
                },
                {
                    props:{size:'xlarge'},
                    style:{
                        fontSize: '2.2rem'
                    },
                    
                }

            ],
            

        },
        MuiButtonBase: {
            styleOverrides :{
                root:{
             
                    backgroundColor: hometheme.palette.primary.main,
                    color: 'white', // hometheme.palette.primary.main,
                    fontSize: '.8rem',//theme.fontSize.medium,
                    fontFamily: 'League Spartan',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                   
                    border: "none",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    paddingTop: ".8rem",
                    paddingBottom: ".8rem",
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: hometheme.palette.primary.main,
                        border:`1px solid ${hometheme.palette.primary.main}`,
                    }
                }
            },
            variants: [
            {
                props: { variant: 'dashed' },
                style: {
                textTransform: 'none',
                border: `2px dashed ${blue[500]}`,
                },
            },
            {
                props: { variant: 'dashed', color: 'secondary' },
                style: {
                border: `4px dashed ${red[500]}`,
                },
            },
            ],
        },
    },
    
  });
theme = responsiveFontSizes(theme);
export { theme };

