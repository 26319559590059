import {
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    TextField,
    ThemeProvider,
    useMediaQuery
} from '@mui/material';

import { theme } from './Prayer.theme';

import { Search } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { getAll } from '../../api/api';
import { FilteredPrayerList } from './FilteredList';
import { NewPrayer } from './NewPrayer';

export function Prayer() {
    const limit = 10;
    const [page, setPage] = useState(1);
    const [searchText,setSearchText] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    
    //Filter list of events to remove any that says dont share
    const filterPrayers = (list) =>{
        return list.filter(ev => !(ev.share.toLowerCase() == 'no')).reverse();
    }
    const [prayerList,setPrayerList] = useState([]);
    const [likes,setLikes] = useState(0);
    
    useEffect(()=>{
        getAll('prayer').then(data=>{
            setPrayerList(filterPrayers(data.data));
            setisLoading(false);
        });
     
    },[likes]);

    const [openNP, setOpenNP] = useState(false);

    const handleOpenNP = () =>{
        setOpenNP(true);
    }
   
    return(
        <ThemeProvider theme={theme}>
            {(isLoading && prayerList.length>0) ? 
                <Grid variant='main'>
                    <CircularProgress variant='loading' />
                </Grid>
            :
                <Grid variant='main'>
                    {openNP? <NewPrayer npState={{openNP,setOpenNP,setPrayerList,filterPrayers,matches}}/>
                    :<></>}                    
                    <Grid variant={matches?'smalltop':'top'}>
                        <Button onClick={()=>handleOpenNP}>Request Prayer</Button>
                        <TextField id="outlined-basic" label="Search" variant="outlined" 
                            InputProps={{
                            endAdornment:( 
                            <InputAdornment position="end">
                                <Search
                                />
                                </InputAdornment>)
                        }}
                        onChange={event => setSearchText(event.target.value)}
                        value={searchText}
                        />
                    </Grid>
                    <FilteredPrayerList prayerList={prayerList} searchText={searchText} page={page} limit={limit} 
                    setLikes={setLikes} matches={matches}
                      />
                </Grid>
            }
        </ThemeProvider>
    )




}